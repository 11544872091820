
.ratingC {
    li div {
      font-size: 35px !important;
    }
   
    .ant-rate-star-first { 
        color: var(--corpo-color);
    }   
    .ant-rate-star-second { 
        color: var(--corpo-color);
    }   
}

.hollidays {
    width: 95% !important;
}