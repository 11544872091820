.aSider {
  .logo {
    padding: 10px;
    img {
      height: 40px !important;
    }
  }
  .ant-menu {
    margin-top: 28px !important;
  }
}

.actions {
  height: 100%;
  margin-right: 10px;
  display: flex;
  float: right;
  > * {
    margin-left: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    .notice {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}
.user-action {
  cursor: pointer;
}
.user-avator {
  margin-right: 8px;
  width: 40px;
  height: 40px;
}

.allContent {
 
  margin: 60px 16px 0 !important;
  padding-bottom: 50 !important;
  z-index: 10;

}