

.nice-dates-day:before {
  background-color: var(--corpo-color) !important;
  color: #fff !important;
}

.nice-dates-day:after {
  border: 2px solid var(--corpo-color) !important;
}

.nice-dates-navigation_current {
  font-family: "Lato", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  font-weight: 700;
  /* identical to box height, or 200% */

  color: #000;
}

.nice-dates-navigation_current {
  font-family: "Lato", sans-serif !important;
  color: var(--corpo-color-hover) !important;
}

.nice-dates-navigation_previous,
.nice-dates-navigation_next {
  color: var(--corpo-color-hover) !important;
}
.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: none;
}
.dCalendar {
    margin: 5px;
   
  
}

.resume {
  margin-top: 10px !important;
  font-weight: 600;
}
.horasTot {
  float: right;
  font-weight: 700;
}
.hora {
  width: 50px !important;
}